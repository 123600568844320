import React from "react";
import Form from "react-bootstrap/Form";
import CurrencyInput from "react-currency-input-field";
import FormCol from "../../../FormCol";

function PersonalFinancial({
    personalFinancialData,
    handlePersonalFinancialChange,
    readOnly,
}) {
    
return (
    <>
        <FormCol>
            <Form.Label>Marital Status</Form.Label>
            <Form.Control
                type="text"
                name="maritalStatus"
                value={personalFinancialData?.maritalStatus}
                onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.value)}
                disabled={readOnly}
            />
        </FormCol>
        <FormCol>
            <Form.Label>Combined Income</Form.Label>
            <CurrencyInput
                prefix="$"
                name="combinedIncome"
                value={personalFinancialData?.combinedIncome}
                decimalsLimit={2}
                onValueChange={(value, name) => handlePersonalFinancialChange(name, value)}
                disabled={readOnly}
            />
        </FormCol>
        <FormCol>
            <Form.Label>Occupation</Form.Label>
            <Form.Control
                type="text"
                name="occupation"
                value={personalFinancialData?.occupation}
                onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.value)}
                disabled={readOnly}
                onBlur={(e) => handlePersonalFinancialChange(e.target.name, e.target.value.trimEnd())}
            />
        </FormCol>
        <FormCol>
            <Form.Label>Monthly Housing Cost</Form.Label>
            <CurrencyInput
                prefix="$"
                name="monthlyHousingCost"
                value={personalFinancialData?.monthlyHousingCost}
                decimalsLimit={2}
                onValueChange={(value, name) => handlePersonalFinancialChange(name, value)}
                disabled={readOnly}
            />
        </FormCol>
        <FormCol>
            <Form.Label>Model Year</Form.Label>
            <Form.Control
                type="text"
                name="modelYear"
                value={personalFinancialData?.modelYear}
                onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.value)}
                disabled={readOnly}
            />
        </FormCol>
        <FormCol>
            <Form.Label>Car Make</Form.Label>
            <Form.Control
                type="text"
                name="carMake"
                value={personalFinancialData?.carMake}
                onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.value)}
                onBlur={(e) => handlePersonalFinancialChange(e.target.name, e.target.value.trimEnd())}
                disabled={readOnly}
            />
        </FormCol>
        <FormCol>
            <Form.Label>Monthly Expenses</Form.Label>
            <CurrencyInput
                prefix="$"
                name="monthlyExpenses"
                value={personalFinancialData?.monthlyExpenses}
                decimalsLimit={2}
                onValueChange={(value, name) => handlePersonalFinancialChange(name, value)}
                disabled={readOnly}
            />
        </FormCol>
        <FormCol>
            <Form.Label>Number of Children</Form.Label>
            <Form.Control
                type="number"
                name="numOfChildren"
                value={personalFinancialData?.numOfChildren}
                onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.value)}
                disabled={readOnly}
            />
        </FormCol>
        <FormCol>
            <Form.Label>Savings</Form.Label>
            <CurrencyInput
                prefix="$"
                name="savings"
                value={personalFinancialData?.savings}
                decimalsLimit={2}
                onValueChange={(value, name) => handlePersonalFinancialChange(name, value)}
                disabled={readOnly}
            />
        </FormCol>
        <FormCol>
            <Form.Label>Credit Available</Form.Label>
            <CurrencyInput
                prefix="$"
                name="creditAvailable"
                value={personalFinancialData?.creditAvailable}
                decimalsLimit={2}
                onValueChange={(value, name) => handlePersonalFinancialChange(name, value)}
                disabled={readOnly}
            />
        </FormCol>
        <FormCol>
            <Form.Label>Own Home</Form.Label>
            <Form.Check
                type="checkbox"
                name="ownHome"
                checked={personalFinancialData?.ownHome}
                onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.checked)}
                disabled={readOnly}
            />
        </FormCol>
        <FormCol>
            <Form.Label>Rent Home</Form.Label>
            <Form.Check
                type="checkbox"
                name="rentHome"
                checked={personalFinancialData?.rentHome}
                onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.checked)}
                disabled={readOnly}
            />
        </FormCol>
        <FormCol>
            <Form.Label>Car Paid Off</Form.Label>
            <Form.Check
                type="checkbox"
                name="carPaidOff"
                checked={personalFinancialData?.carPaidOff}
                onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.checked)}
                disabled={readOnly}
            />
        </FormCol>
    </>
);
}

export default PersonalFinancial;