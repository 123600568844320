import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import useFetch from '../Utilities/useFetch';
import 'bootstrap/dist/css/bootstrap.min.css';


const ReportTemplateDateNoClick = ({
    columnDefs,
    apiUrl,
    reportName
}) => {
    console.log('the apiUrl is:', apiUrl);
    
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]); // Initialize with today's date
    const [rowData, setRowData] = useState([]);
    const { data, error } =  useFetch(true, `${apiUrl}?reportDate=${date}`, 'GET');
    if (error) {
        setRowData([]); // Clear data if the response is not OK
        console.error('Error fetching data:'+{date}, error);
    }

    useEffect(() => {
        if (data) {
            setRowData(data);
        }
    }, [data]);
   
     const handleDateChange = (newDate) => {
        setDate(newDate);
    };
    if (error) {
        console.error('Error fetching data:', error);
    }
 
 
    const DatePicker = ({ value }) => {
        return (
            <input
                type="date"
                value={value}
                onChange={(e) =>  handleDateChange(e.target.value)}
            />
        );
    };

    return (
        <div>
            <h3 className='margin'>{reportName}</h3>
            <div className="date-picker">
                <label htmlFor="report-date">Select Report Date: &nbsp;</label>
                <DatePicker value={date} /> <span> &nbsp; Row Count: {rowData.length}</span>
                </div>
                {error && <div className="error-message">Error fetching data: {error.message}</div>}
            <div className="ag-theme-alpine grid">
                
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={{ sortable: true, filter: true }}
                    onFirstDataRendered={(params) => params.api.sizeColumnsToFit()}
                />
            </div>
         </div>
    );
};

export default ReportTemplateDateNoClick;