import React, { useState } from 'react'
import { Alert, Button, Spinner } from 'react-bootstrap'
import FuncFetch from '../../Utilities/funcFetch'
import { useMsal } from '@azure/msal-react'

function CloseOrOpenAccountButton({
    account,
    updateAccountFieldsAndActivities,
    accountIsOpen,
    setAccountIsOpen,
    className
}) {
    const {instance, accounts} = useMsal();
    const [postingData, setPostingData] = useState(false);
    const { REACT_APP_API_URL } = process.env;
    const [APIError, setAPIError] = useState();

    const handleToggleAccountStatus = () => { 
        setPostingData(true)
        FuncFetch(`${REACT_APP_API_URL}/Claims/ToggleAccountStatus`,{debtorID:account.debtorID},'POST',null, instance, accounts)
            .then(response=>{
                setAccountIsOpen((response.closingHistory[0].newStatus.toUpperCase())==='OPEN'?true:false)
                updateAccountFieldsAndActivities({...response,accountStatus:response.closingHistory[0].newStatus})
                setPostingData(false)
            })
            .catch(err=>{
                console.error(err)
                setAPIError('There was an issue, please try again.')
                setPostingData(false)
            })
     }
  return (
    <>
    <Button className={className||''} variant={accountIsOpen?'danger':'success'} disabled={postingData} onClick={handleToggleAccountStatus}>{postingData?<Spinner animation='border' />:accountIsOpen?'Close Account':'Open Account'}</Button>
    {APIError?
        <Alert variant='danger'>{APIError}</Alert>
    :null}
    </>
  )
}

export default CloseOrOpenAccountButton