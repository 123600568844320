import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Tabs,
  Tab,
  Col,
  Row,
  Spinner,
  Card,
  Form,
  InputGroup,
} from "react-bootstrap";
import PaymentHistoryForm from "../../PaymentHistoryPopup/PaymentHistoryForm";
import Comments from "../../../AccountDetailsPopup/AccordionItems/Comments";
import AdverseCarrierDropdown from "../../AdverseCarrier/AdverseCarrierDropdown";
import FormRow from "../../FormRow";
import useFetch from "../../../Utilities/useFetch";
import SkipTraceToggleCheckbox from "../../SkipTraceToggleCheckbox";
import AdverseCarrierForm from "../../AdverseCarrier/AdverseCarrierForm";
import StatusChangeModal from "../../StatusChangeModal";
import { useMsal } from "@azure/msal-react";
import funcFetch from "../../../Utilities/funcFetch";
import ErrorBoundary from "../../../Auth/ErrorBoundary";
import Financial from "./Financial/Financial";
import PersonalFinancial from "./Financial/PersonalFinancial";
import General from "./General/General";
import Arbitration from "./Arbitration/Arbitration";
import LanguageSelect from "./LanguageSelect";
import IncreaseContactCountButton from "./General/IncreaseContactCountButton";

const CollectorClaimView = ({
  claimNumber,
  loading,
  activities,
  setActivities,
  account,
  setAccount,
  updateAccountFieldsAndActivities,
}) => {
  const { REACT_APP_API_URL } = process.env;
  const { data: isPaymentAdmin, loading: loadingIsPaymentAdmin } = useFetch(
    true,
    `${REACT_APP_API_URL}/Claims/IsPaymentAdmin`,
    "GET"
  );
  const { data: projects, loading: loadingProjects } = useFetch(
    true,
    `${REACT_APP_API_URL}/Claims/GetProjects`,
    "GET"
  );
  const { data: isClaimCreator } = useFetch(
    true,
    `${REACT_APP_API_URL}/Claims/isClaimCreator`,
    "GET"
  );
  const [projectOptions, setProjectOptions] = useState();
  const [selectedClient] = useState();
  const { instance, accounts } = useMsal();
  const [selectedAdverseCarrier, setSelectedAdverseCarrier] = useState();
  const [statusChangeIsOpen, setStatusChangeIsOpen] = useState(false);
  const [postingData, setPostingData] = useState(false);
  const [APIResponse, setAPIResponse] = useState();
  const [APIError, setAPIError] = useState();
  const [readOnly] = useState(false);
  const [key, setKey] = useState("general");
  const aYearFromNow = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  )
    .toISOString()
    .split("T")[0];

  useEffect(() => {
    if (projects) {
      setProjectOptions(
        projects.map((project) => {
          return { value: project.key, label: project.value };
        })
      );
    }
  }, [projects]);

  useEffect(() => {
    if (claimNumber) {
      setAPIError(null);
      setAPIResponse(null);
      setSelectedAdverseCarrier(null);
    }
  }, [claimNumber]);

  const handleChange = (valueName, value) => {
    if (valueName.toLowerCase().includes("date") && value === "") {
      value = null;
    }
    setAPIError(null);
    setAPIResponse(null);
    let newAccountObj = { ...account };
    newAccountObj.account[valueName] = value;
    setAccount(newAccountObj);
  };

  const handlePersonalFinancialChange = (valueName, value) => {
    setAPIError(null);
    setAPIResponse(null);
    let newAccountObj = { ...account };
    if (newAccountObj.personalFinancial === null) {
      newAccountObj.personalFinancial = {};
    }
    newAccountObj.personalFinancial[valueName] = value;
    setAccount(newAccountObj);
  };

  const handleFinancialChange = (valueName, value) => {
    setAPIError(null);
    setAPIResponse(null);
    let newAccountObj = { ...account };
    newAccountObj.account[valueName] = value;
    newAccountObj.financial[valueName] = value;
    setAccount(newAccountObj);
  };

  const handleSubmit = (e) => {
    setPostingData(true);
    setAPIError(null);
    setAPIResponse(null);
    e.preventDefault();
    if (claimNumber) {
      funcFetch(
        `${REACT_APP_API_URL}/Claims/PostAccount`,
        null,
        "POST",
        {
          account: account.account,
          financial: account.financial,
          personalFinancial: account.personalFinancial,
        },
        instance,
        accounts
      )
        .then((response) => {
          setPostingData(false);
          setAPIResponse("Successfully saved changes");
          setAccount(response);
        })
        .catch((err) => {
          console.error(err);
          setAPIError("There was an issue, please try again.");
          setPostingData(false);
        });
    } else {
      funcFetch(
        `${REACT_APP_API_URL}/Claims/GenerateDebtorid`,
        {
          clientID: selectedClient.value.clientID,
          clientRefNo: account.account.clientRefNo,
        },
        "POST",
        {},
        instance,
        accounts
      ).then((debtorID) => {
        account.account.debtorID = debtorID;
        setAccount(account);
        funcFetch(
          `${REACT_APP_API_URL}/Claims/PostAccount`,
          null,
          "POST",
          { account: account.account, financial: account.financial },
          instance,
          accounts
        )
          .then((response) => {
            setPostingData(false);
          })
          .catch((error) => {
            setAPIError("There was an issue, please try again.");
            console.error(error);
            setPostingData(false);
          });
      });
    }
  };

  return (
    <>
      {statusChangeIsOpen ? (
        <StatusChangeModal
          show={statusChangeIsOpen}
          setShow={setStatusChangeIsOpen}
          account={account}
          setAccount={setAccount}
          updateAccountFieldsAndActivities={updateAccountFieldsAndActivities}
        />
      ) : null}
      <ErrorBoundary>
        <Row className="claim-lookup-wrapper">
          <Col>
            <form id="claim-info" onSubmit={(e) => handleSubmit(e)}>
              {loading ? (
                <Alert>
                  Getting claim info <Spinner animation="border" size="sm" />
                </Alert>
              ) : (
                account.financial && (
                  <>
                    <FormRow className="light-grey-background">
                      <h6>Skip Trace</h6>
                      <div className="skip-trace-wrapper">
                        <Form.Label>Needed</Form.Label>
                        <SkipTraceToggleCheckbox
                          readOnly={readOnly}
                          defaultChecked={account.accountFlags?.skipTraceNeeded}
                          debtorid={account.account?.debtorID}
                          updateAccountFieldsAndActivities={
                            updateAccountFieldsAndActivities
                          }
                        />
                        <Form.Label>Completed</Form.Label>
                        <Form.Check
                          checked={account.accountFlags?.skipTraceCompleted}
                        />
                        <Form.Control
                          className="skip-trace-date-input"
                          disabled
                          value={
                            account.accountFlags?.skipTraceDate?.split("T")[0]
                          }
                          type="date"
                        />
                        <LanguageSelect
                          account={account}
                          setAccount={setAccount}
                          updateAccountFieldsAndActivities={
                            updateAccountFieldsAndActivities
                          }
                        />
                        <InputGroup style={{ width: "75px" }}>
                          <Form.Control
                            value={account.account?.contactCount}
                            type="number"
                            readOnly
                            style={{ width: "30px" }}
                          />
                          <IncreaseContactCountButton 
                            debtorID={account.account?.debtorID}
                            instance={instance}
                            accounts={accounts}
                            setAccount={setAccount}
                            updateAccountFieldsAndActivities={updateAccountFieldsAndActivities}
                          />
                        </InputGroup>
                      </div>
                    </FormRow>
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => setKey(k)}
                      className="mb-3"
                    >
                      <Tab eventKey="general" title="General">
                        <General
                          account={account}
                          setAccount={setAccount}
                          handleChange={handleChange}
                          handleFinancialChange={handleFinancialChange}
                          updateAccountFieldsAndActivities={
                            updateAccountFieldsAndActivities
                          }
                          isClaimCreator={isClaimCreator}
                          projectOptions={projectOptions}
                          loadingProjects={loadingProjects}
                          setStatusChangeIsOpen={setStatusChangeIsOpen}
                          readOnly={readOnly}
                        />
                        <FormRow>
                          <Button type="submit">
                            {postingData ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "Save Changes"
                            )}
                          </Button>
                          {APIResponse ? <Alert>{APIResponse}</Alert> : null}

                          {APIError ? (
                            <Alert variant="warning">{APIError}</Alert>
                          ) : null}
                        </FormRow>
                      </Tab>

                      <Tab eventKey="financial" title="Financial">
                        <FormRow className="black-outline">
                          <Financial
                            account={account}
                            handleFinancialChange={handleFinancialChange}
                            handleChange={handleChange}
                            updateAccountFieldsAndActivities={
                              updateAccountFieldsAndActivities
                            }
                            readOnly={readOnly}
                            isClaimCreator={isClaimCreator}
                            aYearFromNow={aYearFromNow}
                          />
                        </FormRow>
                        <FormRow className="black-outline">
                          <PersonalFinancial
                            personalFinancialData={account.personalFinancial}
                            handlePersonalFinancialChange={
                              handlePersonalFinancialChange
                            }
                            readOnly={readOnly}
                          />
                        </FormRow>
                        <FormRow>
                          <Button type="submit">
                            {postingData ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "Save Changes"
                            )}
                          </Button>
                          {APIResponse ? <Alert>{APIResponse}</Alert> : null}

                          {APIError ? (
                            <Alert variant="warning">{APIError}</Alert>
                          ) : null}
                        </FormRow>
                      </Tab>

                      <Tab eventKey="arbitration" title="Arbitration">
                        <FormRow className="black-outline">
                          <Arbitration
                            arbitration={account.account}
                            handleChange={handleChange}
                            readOnly={readOnly}
                            aYearFromNow={aYearFromNow}
                          />
                        </FormRow>
                        <FormRow>
                          <Button type="submit">
                            {postingData ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "Save Changes"
                            )}
                          </Button>
                          {APIResponse ? <Alert>{APIResponse}</Alert> : null}

                          {APIError ? (
                            <Alert variant="warning">{APIError}</Alert>
                          ) : null}
                        </FormRow>
                      </Tab>

                      <Tab eventKey="adverseCarrier" title="Adverse Carrier">
                        <FormRow className="adverse-carrier-dropdown">
                          <Form.Label>Adverse Carrier</Form.Label>
                          <AdverseCarrierDropdown
                            adverseCarriers={account.adverseCarriers}
                            selectedAdverseCarrier={selectedAdverseCarrier}
                            setSelectedAdverseCarrier={
                              setSelectedAdverseCarrier
                            }
                          />
                          <Button
                            onClick={() =>
                              setSelectedAdverseCarrier({
                                value: {
                                  adverseCarrierID: 0,
                                  debtorid: account.account.debtorID,
                                  adverseCarrier: "",
                                  adverseAdjuster: "",
                                  address1: "",
                                  address2: "",
                                  city: "",
                                  state: "",
                                  zip: "",
                                  tel1: "",
                                  ext1: "",
                                  tel2: "",
                                  ext2: "",
                                  fax: "",
                                  claim: "",
                                  tpa: "",
                                  email: "",
                                  email1: "",
                                  email2: "",
                                  subDemandSent: false,
                                  policyHolder: "",
                                  denialOfCoverage: false,
                                  denialOfLiability: false,
                                },
                              })
                            }
                          >
                            Add New
                          </Button>
                        </FormRow>
                        {selectedAdverseCarrier ? (
                          <AdverseCarrierForm
                            className={"black-outline"}
                            adverseCarrier={selectedAdverseCarrier.value}
                            updateAccountFieldsAndActivities={
                              updateAccountFieldsAndActivities
                            }
                          />
                        ) : null}
                        <FormRow>
                          {APIResponse ? <Alert>{APIResponse}</Alert> : null}

                          {APIError ? (
                            <Alert variant="warning">{APIError}</Alert>
                          ) : null}
                        </FormRow>
                      </Tab>
                    </Tabs>
                  </>
                )
              )}
            </form>
          </Col>
          <Col>
            {claimNumber ? (
              <div className="margin-top margin-bottom">
                <div>
                  {activities ? (
                    <Card>
                      <Comments
                        activities={activities}
                        setActivities={setActivities}
                        debtorID={account.account?.debtorID}
                        updateAccountFieldsAndActivities={
                          updateAccountFieldsAndActivities
                        }
                      />
                    </Card>
                  ) : (
                    <Spinner animation="border" />
                  )}
                </div>
                <Card className="payment-history-wrapper margin-top">
                  {account.paymentHistory && projects && !loadingIsPaymentAdmin
                    ? account.paymentHistory.map((payment) => {
                        return (
                          <div className="individual-payment-wrapper">
                            <PaymentHistoryForm
                              defaultFormData={payment}
                              projects={projects}
                              loadingProjects={loadingProjects}
                              readOnly={
                                isPaymentAdmin || isClaimCreator ? false : true
                              }
                              debtorID={account.account.debtorID}
                              updateAccountFieldsAndActivities={
                                updateAccountFieldsAndActivities
                              }
                            />
                          </div>
                        );
                      })
                    : null}
                </Card>
              </div>
            ) : null}
          </Col>
        </Row>
      </ErrorBoundary>
    </>
  );
};

export default CollectorClaimView;
