import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import FormCol from "../../../FormCol";
import useFetch from "../../../../Utilities/useFetch";
import ReactSelect from "react-select";
import { Spinner } from "react-bootstrap";

function Arbitration({
    arbitration,
    handleChange,
    readOnly,
    aYearFromNow,
}) {
  const {data: arbitrators, loading: loadingArbitrators} = useFetch(true,`${process.env.REACT_APP_API_URL}/Claims/GetArbitrators`,"GET");
  const [arbitratorOptions, setArbitratorOptions] = useState([]);

  useEffect(() => {
    if (arbitrators) {
      setArbitratorOptions(arbitrators.map((arbitrator) => ({
        value: arbitrator.key,
        label: arbitrator.value,
      })));
    }
  }, [arbitrators]);
  return (
    <>
      <FormCol>
        <Form.Label>File Date</Form.Label>
        <Form.Control
          disabled={readOnly}
          value={arbitration.arbFileDate?.split("T")[0]}
          max={aYearFromNow}
          type="date"
          onChange={(e) => handleChange("arbFileDate", e.target.value)}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Assigned To</Form.Label>
        {loadingArbitrators?
        <Spinner animation="border" size="sm" />
        :
        <ReactSelect
          isDisabled={readOnly}
          value={arbitratorOptions.find(option => option.value === arbitration.arbFiledBy)}
          onChange={(e) => handleChange("arbFiledBy", e.value)}
          options={arbitratorOptions}
          className="full-width"
        />}
      </FormCol>
      <FormCol>
        <Form.Label>Docket Number</Form.Label>
        <Form.Control
          disabled={readOnly}
          value={arbitration.arbDocketNumber}
          onChange={(e) => handleChange("arbDocketNumber", e.target.value)}
          onBlur={(e) => handleChange("arbDocketNumber", e.target.value.trimEnd())}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Hearing Date</Form.Label>
        <Form.Control
          disabled={readOnly}
          value={arbitration.arbHearingDate?.split("T")[0]}
          max={aYearFromNow}
          type="date"
          onChange={(e) => handleChange("arbHearingDate", e.target.value)}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Decision Date</Form.Label>
        <Form.Control
          disabled={readOnly}
          value={arbitration.arbDecisionDate?.split("T")[0]}
          max={aYearFromNow}
          type="date"
          onChange={(e) => handleChange("arbDecisionDate", e.target.value)}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Decision Reviewed</Form.Label>
        <Form.Check
          type="checkbox"
          name="arbDecisionReviewed"
          checked={arbitration.arbDecisionReviewed}
          onChange={(e) => handleChange("arbDecisionReviewed", e.target.checked)}
          disabled={readOnly}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Decision Reason</Form.Label>
        <Form.Control
          type="text"
          name="arbDecisionReason"
          value={arbitration.arbDecisionReason}
          onChange={(e) => handleChange("arbDecisionReason", e.target.value)}
          onBlur={(e) => handleChange("arbDecisionReason", e.target.value.trimEnd())}
          disabled={readOnly}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Award Percentage</Form.Label>
        <Form.Control
          disabled={readOnly}
          type="number"
          value={arbitration.arbAwardPercentage}
          onChange={(e) => handleChange("arbAwardPercentage", e.target.value)}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Received Date</Form.Label>
        <Form.Control
          disabled={readOnly}
          value={arbitration.arbReceivedDate?.split("T")[0]}
          max={aYearFromNow}
          type="date"
          onChange={(e) => handleChange("arbReceivedDate", e.target.value)}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Recorded Statement Date</Form.Label>
        <Form.Control
          disabled={readOnly}
          value={arbitration.arbRecordedStatementDate?.split("T")[0]}
          max={aYearFromNow}
          type="date"
          onChange={(e) => handleChange("arbRecordedStatementDate", e.target.value)}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Recorded Statement Form Date Sent</Form.Label>
        <Form.Control
          disabled={readOnly}
          value={arbitration.arbRecordedStatementFormDateSent?.split("T")[0]}
          max={aYearFromNow}
          type="date"
          onChange={(e) => handleChange("arbRecordedStatementFormDateSent", e.target.value)}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Statement Needed</Form.Label>
        <Form.Check
          type="checkbox"
          checked={arbitration.arbStatementNeeded}
          onChange={(e) => handleChange("arbStatementNeeded", e.target.checked)}
          disabled={readOnly}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Statement Received</Form.Label>
        <Form.Check
          type="checkbox"
          checked={arbitration.arbRecordedStatementReceived}
          onChange={(e) => handleChange("arbRecordedStatementReceived", e.target.checked)}
          disabled={readOnly}
        />
      </FormCol>
    </>
  );
}

export default Arbitration;