import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Spinner, Alert } from "react-bootstrap";
import FuncFetch from "../../../../Utilities/funcFetch";
import { useMsal } from "@azure/msal-react";

const ExtRqstModal = ({
  show,
  setShow,
  debtorID,
  account,
  setAccount,
  updateAccountFieldsAndActivities,
}) => {
  const [daysRequested, setDaysRequested] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { instance, accounts } = useMsal();

  useEffect(() => {
    console.log(account)
    if(account.account.witness){
      handleSubmit()
      setShow(false)
    }
    // eslint-disable-next-line
  }, [account]);

  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    FuncFetch(
      `${process.env.REACT_APP_API_URL}/Claims/PostExtRqst`,
      { debtorid: debtorID, days: daysRequested },
      "POST",
      {},
      instance,
      accounts
    )
      .then((res) => {
        setAccount(res);
        updateAccountFieldsAndActivities({ 
            activities: res.activities,
            solAdjustment: res.account.solAdjustment,
            witness: res.account.witness,
            targetDate: res.account.targetDate,
         });
        setLoading(false);
        setShow(false);
      })
      .catch((error) => {
        setError("An error occurred");
        setLoading(false);
      });
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Request Extension</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
          <Form.Group controlId="daysRequested">
            <Form.Label>Days Requested</Form.Label>
            <Form.Control
              type="number"
              max={100}
              value={daysRequested}
              onChange={(e) => setDaysRequested(e.target.value)}
              required
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="half-width"
          variant="secondary"
          onClick={() => setShow(false)}
        >
          Close
        </Button>
        <Button
          className="half-width"
          variant="primary"
          onClick={()=>handleSubmit()}
          disabled={loading}
        >
          {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExtRqstModal;
