import React from 'react';
import ReportTemplateDateNoClick from './ReportTemplateDateNoClick';
import { formatDate } from '../Utilities/GridTools';


const Performance = () => {
    //const [date, setDate] = useState(null);
    

    const columns = [
        { field: 'fullName', headerName: 'Name', width: 150 },
        { field: 'collector', headerName: 'Collector', width: 120 },
        { field: 'groupName', headerName: 'Group', width: 120 },
        { field: 'day', headerName: 'Day', width: 120, valueFormatter: formatDate, filter: 'agDateColumnFilter' },
        { field: 'claimstouched', headerName: 'Claims Touched', width: 150 },
        { field: 'totalnotes', headerName: 'Total Notes', width: 150 },
        { field: 'timeonphone', headerName: 'Time on Phone', width: 150 },
        { field: 'numofcalls', headerName: 'No of Calls', width: 150 },
        { field: 'averagecall', headerName: 'Average Call', width: 150 }
    ];

    const apiUrl = `${process.env.REACT_APP_API_URL}/Reports/Performance`;

    return (
       <ReportTemplateDateNoClick columnDefs={columns} apiUrl={apiUrl} reportName={'Staff Performance'} />
    );
};

export default Performance;