import React, { useState } from 'react';
import { Button, Spinner, Alert } from 'react-bootstrap';
import { GiAwareness } from 'react-icons/gi';
import FuncFetch from '../../../../Utilities/funcFetch';

const IncreaseContactCountButton = ({
    debtorID,
    instance,
    accounts,
    setAccount,
    updateAccountFieldsAndActivities
}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleClick = async () => {
        setLoading(true);
        setError(null);
        FuncFetch(`${process.env.REACT_APP_API_URL}/Claims/RecordContact`, {debtorID}, 'POST', {}, instance, accounts)
        .then((res) => {
            setAccount(res);
            updateAccountFieldsAndActivities({
                activities: res.activities,
                contactCount: res.account.contactCount,
            });
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setError('An error occurred while recording contact.');
            setLoading(false);
        });
    };

    return (
        <>
            <Button variant='success' onClick={handleClick} disabled={loading}>
                {loading ? <Spinner animation="border" size="sm" /> : <GiAwareness />}
            </Button>
            {error && <Alert variant="danger" className="mt-2">{error}</Alert>}
        </>
    );
};

export default IncreaseContactCountButton;