import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function General({ accountDetails, statusCode }) {
    const [claimNumber, setClaimNumber] = useState(accountDetails.clientRefNo ? accountDetails.clientRefNo : '');
    const [placementType, setPlacementType] = useState(accountDetails.projectID ? accountDetails.projectID : '');
    const [adjuster, setAdjuster] = useState(accountDetails.insuranceAdjuster ? accountDetails.insuranceAdjuster : '');
    // const [collectRate, setCollectRate] = useState(accountDetails.collectionRate ? accountDetails.collectionRate : '');
    const [debtorID, setDebtorID] = useState(accountDetails.debtorID ? accountDetails.debtorID : '');
    // const [CRSNumber, setCRSNumber] = useState(accountDetails.crsNumber ? accountDetails.crsNumber : '');
    const [debtorStatus, setStatusCode] = useState(accountDetails.debtorStatus ? accountDetails.debtorStatus : '');
    const [debtorStatusDesc, setStatusCodeDesc] = useState(statusCode.status_Code_Description ? statusCode.status_Code_Description : '');
    const [liabilityPercentage, setLiabilityPercentage] = useState(accountDetails.percentOfLiability ? accountDetails.percentOfLiability : '');
    const [accountStatus, setAcctStatus] = useState(accountDetails.accountStatus? accountDetails.accountStatus:'');

    return (
        <Card className='padding'>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Claim Number</Form.Label>
                    <Form.Control readOnly value={claimNumber} onChange={(e) => setClaimNumber(e.target.value)} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>SC ID</Form.Label>
                    <Form.Control readOnly value={debtorID} onChange={(e) => setDebtorID(e.target.value)} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Status</Form.Label>
                    <Form.Control readOnly value={accountStatus} onChange={(e)=> setAcctStatus(e.target.value)}/>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Adjuster</Form.Label>
                    <Form.Control readOnly value={adjuster} onChange={(e) => setAdjuster(e.target.value)} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Status Code</Form.Label>
                    <Form.Control readOnly value={debtorStatus} onChange={(e) => setStatusCode(e.target.value)} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Placement Type</Form.Label>
                    <Form.Control readOnly value={placementType} onChange={(e) => setPlacementType(e.target.value)} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Percent Of Liability</Form.Label>
                    
                    <Form.Control readOnly 
                    type="number"
                    min="0"
                    value={liabilityPercentage} onChange={(e) => setLiabilityPercentage(e.target.value)} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Status Description</Form.Label>
                    <Form.Control readOnly value={debtorStatusDesc} onChange={(e) => setStatusCodeDesc(e.target.value)} />
                </Form.Group>

            </Row>
        </Card>
    )
}

export default General