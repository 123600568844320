import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import CollectorClaimModal from '../Claims/ClaimModal/CollectorClaimModal';
import useFetch from '../Utilities/useFetch';

const ReportTemplate = ({
    columnDefs,
    apiUrl,
    reportName
}) => {
    const [selectedDebtorId, setSelectedDebtorId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { data: rowData, error } = useFetch(true, apiUrl, 'GET');

    if (error) {
        console.error('Error fetching data:', error);
    }


    const onCellClicked = (event) => {
        setSelectedDebtorId(event.data.debtorid);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <h3 className='margin'>{reportName}</h3>
            <div className="ag-theme-alpine grid">
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={{ sortable: true, filter: true }}
                    onCellClicked={onCellClicked}
                    onFirstDataRendered={(params) => params.api.sizeColumnsToFit()}
                />
            </div>
            {isModalOpen && (
                <CollectorClaimModal
                    selectedClaim={selectedDebtorId}
                    setSelectedClaim={setSelectedDebtorId}
                    show={isModalOpen}
                    setShow={closeModal}
                />
            )}
        </div>
    );
};

export default ReportTemplate;