import React from 'react';
import { ProfileData } from './Components/ProfileData';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './Components/Auth/ProtectedRoute';
import Login from './Components/Auth/Login';
import NCD from './Components/Claims/NCD/NCD';
import SearchForm from './Components/Claims/Search/SearchForm';
import NoTouch30daylast7 from './Components/Reports/NoTouch/30daylast7';
import NoTouch45daylast7 from './Components/Reports/NoTouch/45daylast7';
import NoTouchPast30daylast14 from './Components/Reports/NoTouch/Past30daylast14';
import NoTouchPast45daylast14 from './Components/Reports/NoTouch/Past45daylast14';
import Performance from './Components/Reports/Performance';
// import SharePointIframe from './Components/SharepointIframe';

export default function App() {
  return (
    <Routes>
      <Route 
        exact 
        path='/' 
        element={<ProtectedRoute><SearchForm /></ProtectedRoute>} 
      />
      <Route 
        path='/openclaims' 
        element={<ProtectedRoute><SearchForm /></ProtectedRoute>} 
      />
      <Route 
        path='/claims/NCD' 
        element={<ProtectedRoute><NCD /></ProtectedRoute>} 
      />
      <Route 
        path='/claims/OpenClaims' 
        element={<ProtectedRoute><SearchForm /></ProtectedRoute>} 
      />
      <Route
        path='/Reports/NoTouch/30daylast7'
        element={<ProtectedRoute><NoTouch30daylast7/></ProtectedRoute>}
      />
      <Route
        path='/Reports/NoTouch/45daylast7'
        element={<ProtectedRoute><NoTouch45daylast7/></ProtectedRoute>}
      />
      <Route
        path='/Reports/NoTouch/Past30daylast14'
        element={<ProtectedRoute><NoTouchPast30daylast14/></ProtectedRoute>}
      />
      <Route
        path='/Reports/NoTouch/Past45daylast14'
        element={<ProtectedRoute><NoTouchPast45daylast14/></ProtectedRoute>}
      />
      <Route
        path='/Reports/Performance'
        element={<ProtectedRoute><Performance/></ProtectedRoute>}
      />
      <Route 
        path='/login' 
        element={<Login />} 
      />
      <Route 
        path='/profile' 
        element={<ProtectedRoute><ProfileData /></ProtectedRoute>} 
      />
    </Routes>
  );
}
