import React from 'react';
import ReportTemplate from '../ReportTemplate';
import { formatDate } from '../../Utilities/GridTools';

const NoTouchPast45daylast14 = () => {
    const columnDefs = [
        { headerName: 'Debtor ID', field: 'debtorid' },
        { headerName: 'Client', field: 'client' },
        { headerName: 'List Date', field: 'listDate', valueFormatter: formatDate, filter: 'agDateColumnFilter' },
        { headerName: 'Debtor Status', field: 'debtorStatus' },
        { headerName: 'Project ID', field: 'projectID' },
        { headerName: 'Last Date', field: 'lastDate', valueFormatter: formatDate, filter: 'agDateColumnFilter' },
        { headerName: 'Deductible', field: 'deductible' },
        { headerName: 'Comment By', field: 'commentBy' },
        { headerName: 'Collector', field: 'collector' },
        { headerName: 'Adjuster', field: 'adjuster' },
        { headerName: 'Amount Paid', field: 'amountPaid' },
        { headerName: 'Principle', field: 'principle' }
    ];

    const apiUrl = `${process.env.REACT_APP_API_URL}/Reports/NoTouch/Past45daylast14`;

    return (
        <ReportTemplate columnDefs={columnDefs} apiUrl={apiUrl} reportName={'Past 45 day last 14'} />
    );
};

export default NoTouchPast45daylast14;