import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import FuncFetch from '../../../Utilities/funcFetch';
import { useMsal } from '@azure/msal-react';
import { Spinner } from 'react-bootstrap';
import { FaCheck, FaTimes } from 'react-icons/fa';

function LanguageSelect({
    account,
    setAccount,
    updateAccountFieldsAndActivities
}) {
    const { instance, accounts } = useMsal();
    const [language, setLanguage] = useState({ value: 'English', label: 'English' });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const languageOptions = [
        { value: 'English', label: 'English' },
        { value: 'Spanish', label: 'Spanish' },
        { value: 'Other', label: 'Other' }
    ];

    useEffect(() => {
        if ((account?.accountFlags?.spokenLanguage)) {
            setLanguage({ value: account.accountFlags.spokenLanguage, label: account.accountFlags.spokenLanguage });
        }
    }, [account]);

    const handleOnChange = (e) => {
        setLanguage(e);
        setLoading(true);
        setSuccess(null);
        FuncFetch(`${process.env.REACT_APP_API_URL}/Claims/PostSpokenLanguage`, { debtorid: account.account.debtorID, language: e.value }, 'POST', {}, instance, accounts)
            .then((res) => {
                updateAccountFieldsAndActivities({ activities: res.activities });
                setAccount(res);
                setLoading(false);
                setSuccess(true);
            })
            .catch((error) => {
                setLoading(false);
                setSuccess(false);
            });    
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <ReactSelect
                options={languageOptions}
                value={language}
                onChange={(e) => handleOnChange(e)}
            />
            {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '10px' }} />}
            {success === true ? <FaCheck color="green" style={{ marginLeft: '10px' }} /> : (success === false) && <FaTimes color="red" style={{ marginLeft: '10px' }} />}
        </div>
    );
}

export default LanguageSelect;