import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import FuncFetch from "../../../../Utilities/funcFetch";
import { useMsal } from "@azure/msal-react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import useFetch from "../../../../Utilities/useFetch";

const AdminPostAccountHeader = ({ show, setShow, account, setAccount }) => {
  const [debtorid] = useState(account.account.debtorID);
  const [clientRefNo, setClientRefNo] = useState(account.account.clientRefNo);
  const [clientid, setClientid] = useState(account.account.clientID);
  const { data: clients } = useFetch(
    true,
    `${process.env.REACT_APP_API_URL}/Claims/GetClients`,
    "GET"
  );
  const [error, setError] = useState(null);
  const [clientOptions, setClientOptions] = useState(null);
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (clients) {
      setClientOptions(
        clients.map((client) => ({
          value: client.key,
          label: client.value,
        }))
      );
    }
  }, [clients]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setError(null);
    FuncFetch(
      `${process.env.REACT_APP_API_URL}/Claims/PostAccountHeader`,
      { debtorid, clientRefNo, clientid },
      "POST",
      null,
      instance,
      accounts
    )
      .then((response) => {
        setSuccess(true);
        setLoading(false);
        setAccount(response);
      })
      .catch((error) => {
        setLoading(false);
        console.error("There was an error posting the account header!", error);
        setError("There was an error, please try again");
      });
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Admin Claim Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formClientRefNo">
            <Form.Label>Client Reference Number</Form.Label>
            <Form.Control
              type="text"
              value={clientRefNo}
              onChange={(e) => setClientRefNo(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formClientSelect">
            <Form.Label>
              Select Client<span className="required-field">*</span>{" "}
            </Form.Label>
            {clientOptions ? (
              <Select
                options={clientOptions}
                value={clientOptions.find(
                  (option) => Number(option.value) === clientid
                )}
                onChange={(e) => setClientid(e.value)}
                required
              />
            ) : (
              <Spinner animation="border" role="status" />
            )}
          </Form.Group>
          {error && (
            <Alert variant="danger" className="margin-top">
              {error}
            </Alert>
          )}
          {success && (
            <Alert variant="success" className="margin-top">
              Changes saved successfully
            </Alert>
          )}
          <Row className="margin-top">
            <Col>
              <Button
                className="full-width"
                variant="secondary"
                onClick={() => setShow(false)}
              >
                Close
              </Button>
            </Col>
            <Col>
              <Button className="full-width" variant="primary" type="submit">
                {loading ? (
                  <Spinner animation="border" role="status" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AdminPostAccountHeader;
